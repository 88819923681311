import React from "react";
import { Link } from "gatsby";
import styles from "./button.module.css";

export default function Button(props) {
  const { url = "/", text, minWidth, margin } = props;
  return (
    <Link
      to={url}
      className={styles.link}
      style={{ minWidth: minWidth, margin: margin }}
    >
      {text}
    </Link>
  );
}
