import React from "react";
import { Link } from "gatsby";
import "../../../../../assets/css/global.css";
import styles from "./styles.module.css";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Button from "../../../components/Button";
import logo1 from "../../../../../assets/img/logogallery/logo-featured-01.jpg";

export default function HauteSecurite() {
  const buttonMargin = "15px auto 10px";
  const iconColor = "#538B23";
  return (
    <div className={styles.container}>
      <h1>Galerie de logos</h1>
      <h2 style={{ margin: "20px 0 30px", color: "black" }}>
        Des exemples de notre travail.
      </h2>

      <p>
        <strong>
          Vous êtes prêt?{" "}
          <Link
            className={styles.link}
            to="/demarrez/conception-de-logo/forfaits-et-tarifs/"
          >
            Cliquez sur le lien pour voir les forfaits et les prix dans le même
            onglet
          </Link>{" "}
          pour commencer la conception du logo.
        </strong>
      </p>

      <div className="clearfix"></div>
    </div>
  );
}
