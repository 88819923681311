import React from "react";
import Layout from "../../../other/layout";
import HauteSecurite from "../../../components/AC-StaticPages/Gerez/Cheques/HauteSecurite/HauteSecurite";

import SEO from "../../../other/seo";

export default function hautesecurite() {
  return (
    <Layout>
      <SEO title="Haute Securite" />
      <HauteSecurite />
    </Layout>
  );
}
